import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

// Static Pages
import Home from "./components/Home";
import Archive from "./components/Archive";
import AiB from "./components/AiB";
import Playbooks from "./components/Playbooks";
import Workshops from "./components/Workshops";
import Contact from "./components/Contact";
import Practice from "./components/Practice";
import Pricing from "./components/Pricing";
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Elevate from './components/Elevate';

// Public endpoints dependent
import Intro from "./components/Intro";
import Draft from "./components/Draft";

// JWT-protected
import Profile from './components/Profile';
import Playbook from './components/Playbook';
// import Subscription from './components/Subscription';
import Inbox from './components/Inbox';

// Functional UI
import Signin from "./components/Signin";
import Signout from "./components/Signout";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import Signup from "./components/Signup";

// Non-ui components
import { HelmetProvider } from 'react-helmet-async';
import Seo from "./components/Seo";
import ScrollToTop from "./components/ScrollToTop";
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import ApiClient from './components/ApiClient';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      toast.error(`Error occurred: ${error.message}`);
    }
  }),
});

queryClient.setDefaultOptions({
  queries: {
    staleTime: Infinity,
    retry: 2,
    retryDelay: 1500,
  },
});

function App() {

  useEffect(() => {
    if (ApiClient.config && ApiClient.config.maintenance) {
      toast.warning(ApiClient.config.maintenance);      
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ToastContainer autoClose={15000}/>

        <HelmetProvider>
          <Seo />
          <Router>
            <ScrollToTop />
            <Navigation />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/practice" element={<Practice />} />
              <Route path='/about' element={<Navigate to='/practice' replace />} />
              <Route path="/aib" element={<AiB />} />
              <Route path="/playbooks" element={<Playbooks />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/draft" element={<Draft />} />
              <Route path="/workshops" element={<Workshops />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/elevate" element={<Elevate />} />

              <Route path="/signin" element={<Signin />} />
              <Route path="/signin/:target" element={<Signin />} />

              <Route path="/signup" element={<Signup />} />
              <Route path="/signup/:target" element={<Signup />} />

              <Route path="/card" element={<Profile />} />
              
              <Route path="/intro/:lead?" element={<Intro />} />
              <Route path="/demo/:lead?" element={<Intro />} />

              <Route path="/playbook/:id?" element={<Playbook />} />
              {/* <Route path="/subscription" element={<Subscription />} /> */}
              <Route path="/inbox" element={<Inbox />} />

              <Route path="/signout" element={<Signout />} />
              <Route path="*" element={<NotFound />} />


            </Routes>
            <Footer />
          </Router>
        </HelmetProvider>

      </div>

    </QueryClientProvider>
  );
}

export default App;
