import Seo from "./Seo";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function Pricing() {

  return (
    <div className="container">
      <Seo title="Pricing" />
      <h2>Pricing</h2>

      <div className="row featurette text-center mb-3">

        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-premium1">
            <div className="card-body">
              <h4 className="card-title">Base</h4>
              <p>Self-service. Get verified and explore playbooks yourself</p>
              <p>$299 a month billed annually</p>
              <p className="card-text">Double execution is better than double-checking. Evaluate moat of technology of interest.</p>

            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/aib-alt">Sign Up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#lab">Learn more</a>
            </div>

          </div>
        </div>


        <div className="col-md-4  mb-1">
          <div className="card h-100 bc-premium2">
            <div className="card-body">
              <h4 className="card-title">Plus</h4>
              <p className="card-text">What workshop plus does</p>
              <p>Upgrade from Contact for pricing</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/aib-flip">Sign Up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#flip">Learn more</a>
            </div>
          </div>
        </div>





        <div className="col-md-4 text-dark mb-1">
          <div className="card h-100 bc-premium3">
            <div className="card-body">
              <h4 className="card-title">Premium</h4>
              <p className="card-text">what workshop premium offers, around MVP.
              </p>
              <p>Contact for pricing</p>

            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/aib-saas">Sign Up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#chassis">Learn more</a>
            </div>

          </div>
        </div>

      </div>

      <div className="row featurette">
        <div className="col-md-7">
          <img className="featurette-image img-fluid" src="/img/main/aib.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-5">
          <p>Asset in a box (AiB) program is designed to allow for more predictable technology acquisitions.</p>
          <p>As technology and business leader you constantly working on expanding palette of features and capabilities serving and providing to your customer.</p>

          <p>This program is meant to support dynamic technology executions. The vision in foundation of it is:</p>
          <ol>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377827604819968" target="_blank" rel="noreferrer">Strategy is all about starting and stopping in time</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377662588342273" target="_blank" rel="noreferrer">Virtual facilities define uniqueness of a business</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376351708958721" target="_blank" rel="noreferrer">Resiliency</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377752740704256" target="_blank" rel="noreferrer">More cycles you continue, better process you get</a></li>
          </ol>

        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-secondary">
            <div className="card-header text-center">
              <p>Ideal for SMB (small and midsize business)</p>
            </div>

            <div className="card-body">
              <h4 className="card-title text-center" id="lab">Alternative lab</h4>
              <p><i className="bi bi-info" /> Before purchasing a SaaS allows for technology evaluation</p>
              <p><i className="bi bi-info" /> Create second tech option for an acquisition</p>
              <p><i className="bi bi-info" /> After purchasing of a SaaS allows for design of best incorporation strategy</p>
              <p><i className="bi bi-info" /> In certain cases, incorporation path includes renovation of an old technology, and white labeling it</p>
              <p><i className="bi bi-info" /> Or incorporating SaaS as part of integral palette of in-house web services</p>
            </div>


            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/aib-alt">Sign Up</Link>
            </div>
          </div>
        </div>


        <div className="col-md-4 mb-1">


          <div className="card h-100 bc-secondary">
            <div className="card-header text-center">
              <p>Ideal for SMB (small and midsize business) </p>
            </div>
            <div className="card-body">
              <h4 className="card-title text-center" id="flip" >Flip a SaaS</h4>
              <p><i className="bi bi-info" /> Established systems do need renovation</p>
              <p><i className="bi bi-info" /> Optimize for better technology operations</p>
              <p><i className="bi bi-info" /> Remove considerable maintenance pressure from the software technology side</p>
              <p><i className="bi bi-info" /> Automation of existing processes which are  manual, or currently absent, or have considerable surface for human error</p>
              <p><i className="bi bi-check" /> Various forms of cooperation can be discussed as this is cornerstone interest of the practice</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/aib-flip">Sign Up</Link>
            </div>
          </div>
        </div>



        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-secondary">
            <div className="card-header text-center">
              <p>Ideal for enterprise in general </p>
            </div>

            <div className="card-body">
              <h4 className="card-title text-center" id="chassis">The Chassis</h4>
              <p><i className="bi bi-info" />  Optimize time to start and stop business line</p>
              <p><i className="bi bi-info" />  Offload creation of services into agile and isolated pipeline</p>
              <p><i className="bi bi-info" />  Experiment, groom, bring up to speed software technology, or abandon assets before they touch your perimeter</p>
              <p><i className="bi bi-info" />  Make sure there is operational framework (software solutions chassis) in place to allow such onboarding</p>

            </div>

            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/aib-saas">Sign Up</Link>
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

export default Pricing;
